.parent-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.left-column {
    width: 100%;
    margin-top: 50px;
    margin-left: 4%;
}

.right-column {
    padding-right: 50px;
}

.sub-container {
    display: flex;
    flex-direction: row;
    margin-left: 0;
}

.bottom-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
}

.info-container {
    flex: 2;
    margin-top: 15px;
}

button {
    padding: 10px 20px;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
    }
}

.hero-image-style {
    width: 110% !important;
    height: 90%;
}

@media (min-width: 768px) {
    .right-column {
        display: flex;
        justify-content: flex-end;
    }
}



.cricfan-header {
    font-family: 'gothic';
    font-size: 80px;
    color: #01FF76;
    margin-top: 10px;
    text-align: left;
}

.additional-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 768px) {
    .left-column {
        margin-left: 0px;
    }

    .cricfan-header {
        text-align: center;
    }

    .cricfan-logo {
        display: none;
    }

    .bottom-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .play-now-button {
        margin-left: 0% !important;
    }

    .trustpilot-rating {
        margin-left: 0px !important;
    }

    .right-column {
        padding-right: 0px;
        margin-top: 20px;
    }

    .additional-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 30px !important;
    }

    .hero-image-style {
        width: 100% !important;
        height: 90%;
    }
}