.parent-div {
  margin-bottom: 1.5rem !important;
}

.card-title {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.card-class {
  border: 2px solid #01ff76;
}

.image-style {
  max-width: 100%;
  max-height: auto;
}

.header-style {
  font-family: gothic;
  font-size: 2.9rem;
  color: #ffffff !important;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
}

.text-style {
  max-width: 100%;
  font-family: gothic;
  font-size: 30px;
}

.guaranteed-row {
  background-color: black;
  color: #01ff76;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 45px;
}

.button-style {
  background-color: #01ff76;
  color: black;
  padding: 5px;
  font-weight: bold;
  font-size: 50px;
  border-radius: 0px;
  height: 80px;
  font-family: gothic;
}

.button-style:hover {
  opacity: 1;
}

.countdown-box-style {
  background-color: #2f2f2f;
  color: white;
  max-width: 25%;
  display: flex;
  font-size: 40px;
  height: 100px;
  padding-top: 5px;
  flex-direction: column;
  font-family: gothic;
  align-items: center;
}

.countdown-class {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.countdown-number-style {
  margin: 0;
  color: white;
}

.placeholder-style {
  font-size: 20px;
  color: white;
}

.progress {
  height: 25px;
  background-color: #2f2f2f;
}

.progress-bar {
  border-radius: 0 !important;
  background-color: #01ff76;
}

.tickets-left {
  font-family: gothic;
  font-size: 20px;
  color: #01ff76;
  background-color: black;
  font-size: 30px;
  margin-bottom: 0px;
}
.responsive-image {
  width: 100%;
  height: 500px;
  padding: 20px;
  object-fit: cover;
}
@media (max-width: 833px) {
  .responsive-image {
    width: 50%;
    height: 300px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .header-style {
    font-family: gothic;
    width: 100%;
    font-size: 2.5rem;
    margin-top: 15px !important;
  }

  .responsive-image {
    width: 50%;
    height: 401px;
    object-fit: cover;
  }
}
