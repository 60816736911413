.winner-header-style {
  font-family: gothic;
  font-size: 80px;
  color: #01ff76;
  margin-top: 30px;
}

.winner-container {
  display: flex;
  overflow-x: auto;
  padding: 0 50px;
}

.winner-item {
  flex: 0 0 33%;
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .winner-container {
    display: flex;
    overflow-x: auto;
    padding: 0 20px;
  }

  .winner-item {
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 20px;
  }
}
