.competitions-header {
    margin-top: 50px !important;
    font-size: 80px !important;
}

@media (max-width: 768px) {
    .competitions-header {
        margin-top: 50px !important;
        font-size: 60px !important;
    }
}