.footer-main {
    color: white;
}

footer {
    display: flex;
    /* background-color: #140027; */
}

.footer-left {
    width: 50%;
    padding: 20px;
    text-align: left;
}

.footer-right {
    width: 50%;
    padding: 20px;
    text-align: right;
}

.social-icons {
    margin-top: 10%;
}

.social-icons img {
    margin-right: 10px;
}

.footer-mobile-only {
    display: none;
}

.footer-web-only {
    display: block;
}

@media (max-width: 768px) {

    footer {
        display: flex;
        flex-direction: column;
    }

    .footer-left {
        width: 100%;
    }

    .footer-right {
        width: 100%;
        text-align: left;
        margin-top: -10px !important;
    }

    .footer-cf-logo {
        width: 30% !important;
    }

    .footer-web-only {
        display: none;
    }

    .footer-mobile-only {
        display: block;
        margin-bottom: -20px !important;
    }
}