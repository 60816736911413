/* TelegramIcon.css */
.telegram-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0088cc;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* Make sure it appears on top of other elements */
}

.telegram-icon:hover {
    background-color: #006699;
}