.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Nav----------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.menu {
  height: 90px;
  padding-right: 18px;
  border-radius: 0px;
  display: flex;
  box-shadow: 0px 7px 11px -4px rgba(0, 0, 0, 0.69);
  align-content: space-around;
  justify-content: space-around;
}

p i {
  position: relative;
  top: 10px;
  left: 10px;
}

.menu ul,
.menu li {
  margin: auto auto;
  padding: 0;
  list-style: none
}

.menu ul {
  width: 100%;
  text-align: right;
}

.menu li {
  display: inline-block;
  position: relative;
}

.menu a {
  display: block;
  line-height: 56px;
  padding: 0 14px;
  text-decoration: none;
  color: #01FF76;
  font-size: 16px;
}

.menu li a:hover {
  color: white;
  text-decoration: none;
}

.menu input {
  display: none;
  margin: 0;
  padding: 0;
  height: 56px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.menu label {
  display: none;
}

.menu label:before {
  font-size: 1.6em;
  color: #595959;
  content: "\2261";
  margin-left: 20px;
}

.menu ul.sub-menus {
  height: auto;
  overflow: hidden;
  width: 170px;
  background: #737373;
  position: absolute;
  z-index: 99;
  display: none;
}

.menu ul.sub-menus li {
  display: block;
  text-align: left;
  width: 100%;
}

.menu ul.sub-menus a {
  font-size: 16px;
}

.menu li:hover ul.sub-menus {
  display: block;
}

.menu ul.sub-menus a:hover {
  background: #F2F2F2;
  color: #000000;
}

@media screen and (max-width: 800px) {
  .menu {
    position: relative;
  }

  .menu ul {
    background: #737373;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 3;
    height: auto;
    display: none;
    text-align: left;
  }

  .menu ul.sub-menus {
    width: 100%;
    position: static;
  }

  .menu ul.sub-menus a {
    padding-left: 30px;
  }

  .menu li {
    display: block;
    float: none;
    width: auto;
  }

  .menu input,
  .menu label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  .menu input {
    z-index: 4;
  }

  .menu label {
    display: block;
    cursor: pointer;
  }

  .menu input:checked+label {
    color: #595959;
  }

  .menu input:checked+label:before {
    content: "\00d7";
  }

  .menu input:checked~ul {
    display: block;
  }
}

.confetti-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}

/* Header */



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


.head {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 58;
}

.head p {
  padding: 15px;
  border-radius: 10px;
}

.head p span {
  padding: 12px;
  border-radius: 7px;
  margin-left: 5px;
}

.head1 {
  display: flex;
  font-size: 40px;
  height: 170px;
  margin-top: -30px;
  font-weight: 500;
  justify-content: center;
  align-content: center;
}

/* Card */

.pricing {
  padding-top: 3em;
  padding-bottom: 3em;
}

@media (max-width: 768px) {
  .pricing {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
  text-align: left;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}

/* Footer----------------- */
.footer-main footer {
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  top: 12px;
}

.card-parent-box {
  margin-left: 50px;
  margin-right: 50px;
}

.web-only {
  display: block;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .card-parent-box {
    margin-left: 15px;
    margin-right: 15px;
  }

  .how-to-play {
    margin-top: 0px !important;
    font-size: 60px !important;
  }

  .web-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}