.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent black background */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    width: 80%;
    /* Adjust as needed */
    max-width: 400px;
    /* Example max-width */
    background-color: black;
    /* Remove white background */
    padding: 20px;
    border-radius: 10px;
    position: relative;
    /* Needed for positioning the close icon */
}

.close-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10000;
    /* Ensure it's above the overlay */
    cursor: pointer;
}

.close-icon i {
    font-size: 20px;
    color: #fff;
    /* Close icon color */
}

.nav-tabs {
    --bs-nav-tabs-link-active-color: #01FF76 !important;
    color: #000000 !important;
}

.nav-link.active {
    color: #000000 !important;
}

.nav-link {
    color: #01FF76 !important;
}