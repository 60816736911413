.circle-slider {
    position: relative;
    width: 250px;
    height: 20px;
}

.circle-image {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.circle-image:first-child {
    transform: translateX(125px);
}

.circle-image:last-child {
    transform: translateX(-125px);
}

@media (max-width: 768px) {
    .circle-slider {
        margin-left: 30px;
        margin-top: 10px;
    }
}