.container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    color: white;
    min-height: 50vh;
}

.vertical-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 0.6;
}

.data {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    align-items: flex-start;
}

.horizontal-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.menu-item {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #333;
    color: #fff;
    width: 250px;
}

.menu-item.active {
    background-color: #01FF76;
    color: black;
    width: 250px;
}

.menu-item-comp {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #333;
    color: #fff;
    width: 350px;
}

.menu-item-comp.active {
    background-color: #01FF76;
    color: black;
    width: 350px;
}

.competition-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: 90%;
    margin: 10px;
}

.competitions-list-container {
    height: 400px;
    /* Adjust height as needed */
    overflow-y: auto;
}

.competitions-list {
    display: flex;
    flex-direction: column;
}

.competitions-list-container::-webkit-scrollbar {
    width: 2px;
    /* Width of the scrollbar */
}

.competitions-list-container::-webkit-scrollbar-thumb {
    background-color: white;
    /* Color of the scrollbar thumb */
    border-radius: 2px;
    /* Radius of the scrollbar thumb */
}

.competition-card h3 {
    text-align: center;
    margin-bottom: 5px;
}

.competition-card p {
    margin: 5px 0;
}

.profile-data p {
    margin-bottom: 5px;
}

.profile-data .combined-info {
    white-space: nowrap;
}

.profile-data .contact-info {
    margin-left: 0;
    text-align: left;
    margin-top: 5px;
}

.profile-data .contact-info .info-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.info-box input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #01FF76;
    color: black;
    cursor: pointer;
}

button:hover {
    background-color: #00cc60;
}

@media (max-width: 768px) {
    .container {
        font-size: 20px;
    }

    .vertical-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .horizontal-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .data {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        margin-top: 10px;
        align-items: center;
    }

    .menu-item {
        display: block;
        margin: 5px 0;
    }
}