.quote-container {
    text-align: center;
    color: white;
    font-family: gothic;
    width: 90%;
}

.quote-mark {
    font-size: 4vw;
    /* Adjust the font size dynamically based on viewport width */
    font-family: gothic;
    font-weight: bold;
}

.quote-mark-left {
    margin-right: 5px;
    font-family: gothic;
}

.quote-mark-right {
    margin-left: 5px;
    font-family: gothic;
    font-size: 2vw;
    /* Adjust the font size dynamically based on viewport width */
}

.quote-text {
    font-size: 2.5vw;
    /* Adjust the font size dynamically based on viewport width */
    font-family: gothic;
}

.author-info {
    font-size: 1.5vw;
    text-align: right;
    font-family: gothic;
}

.author-info p {
    font-family: gothic;
    margin-bottom: 0px !important;
}

/* Media queries for different screen sizes */

/* For small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .quote-mark {
        font-size: 8vw;
    }

    .quote-mark-right {
        font-size: 5vw;
    }

    .quote-text {
        font-size: 4vw;
    }

    .author-info {
        font-size: 4vw;
    }
}

/* For medium devices (tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .quote-mark {
        font-size: 6vw;
    }

    .quote-mark-right {
        font-size: 4vw;
    }

    .quote-text {
        font-size: 3.5vw;
    }

    .author-info {
        font-size: 3.3vw;
    }
}

/* For large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .quote-mark {
        font-size: 4vw;
    }

    .quote-mark-right {
        font-size: 2vw;
    }

    .quote-text {
        font-size: 2.5vw;
    }

    .author-info {
        font-size: 2.3vw;
    }
}

/* For extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .quote-mark {
        font-size: 3vw;
    }

    .quote-mark-right {
        font-size: 1.5vw;
    }

    .quote-text {
        font-size: 2vw;
    }

    .author-info {
        font-size: 1.8vw;
    }
}